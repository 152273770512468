import SpacingBlock from '__designkit__/components/SpacingBlock';
import V2Button from '__pc__/components/common/v2Design/button';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2BackgroundColors } from '__pc__/constant/v2Design/Colors/V2BackgroundColors';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { V2TextColors, V2TextColorsType } from '__pc__/constant/v2Design/Colors/V2TextColors';
import { RadiusValue } from '__pc__/constant/v2Design/RadiusType';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import { V2FontStyleDirect, V2FontStyleType } from '__pc__/constant/v2Design/V2FontStyle';
import { IconArrowBack, IconLogo } from 'consts/assets/icons/iconPages';
import { useCheckMatchApplicantMatchingInformationFieldExists, useMatchApplicantIntroduction, useSaveMatchApplicantIntroduction } from 'query/match/applicants/useMatchApplicantsQuery';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';

import RoutePaths from 'consts/RoutePaths';
import ImgProfile from './imgProfile.png';

const SelfIntroduceFrame = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .wrap {
    display: flex;
    flex-direction: column;
    margin-top: 64px;
    padding: ${SpaceValue.XXL}px ${SpaceValue.L}px 128px ${SpaceValue.L}px;
    overflow-y: scroll;
    /* 한문장 소개 */
    .input-container {
      position: relative;
      width: 100%;
      border: 1px solid ${V2BorderColors.default};
      border-radius: ${RadiusValue.M}px;
    }

    .custom-input {
      width: 100%;
      height: 48px;
      padding: ${SpaceValue.M}px;
      padding-right: 40px; /* 버튼 공간 확보 */
      border: 1px solid ${V2BorderColors.default};
      border-radius: ${RadiusValue.M}px;
      font: ${V2FontStyleDirect.body_1_m};
      color: ${V2TextColors.default};
      outline: none;
      border: none;

      &::placeholder {
        color: ${V2TextColors.subtlest};
      }
    }

    .clear-button {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      background: none;
      border: none;
      font-size: 18px;
      cursor: pointer;
      color: #888;
    }

    /* 자기소개 */
    .text-area-container {
      position: relative;
      width: 100%;
      height: 204px;
      border: 1px solid ${V2BorderColors.default};
      border-radius: ${RadiusValue.M}px;
    }

    .custom-textarea {
      width: 100%;
      height: calc(100% - 24px);
      padding: ${SpaceValue.M}px;
      font: ${V2FontStyleDirect.body_1_m};
      color: ${V2TextColors.default};
      border-radius: ${RadiusValue.M}px;
      resize: none;
      outline: none;
      border: none;

      &::placeholder {
        color: ${V2TextColors.subtlest};
      }
    }

    .length-title {
      position: absolute;
      right: 0;
      bottom: -28px;
      font: ${V2FontStyleDirect.body_3_m};
      color: ${V2TextColors.subtlest};
    }

    .length-text {
      position: absolute;
      right: 12px;
      bottom: 7px;
      font: ${V2FontStyleDirect.body_3_m};
      color: ${V2TextColors.subtlest};
    }
  }
`;

const Header = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  background-color: ${V2BackgroundColors.surface_default};
  z-index: 1;

  .btn-back {
    position: absolute;
    display: block;
    top: 12px;
    left: 12px;
    width: 40px;
    height: 40px;
  }
`;

const ButtonFrame = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: ${SpaceValue.XL}px ${SpaceValue.L}px;
  background-color: ${V2BackgroundColors.surface_default};
`;

const MatchingGuideIntoduce = () => {
  const history = useHistory();
  const [introduceText, setIntroduceText] = useState('');
  const [title, setTitle] = useState('');
  const maxLength = 300;
  const titleMaxLength = 40;

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const positionSn = Number(queryParams.get('positionSn')) || -1;
  const { data: introductionData } = useMatchApplicantIntroduction();
  const { mutate: saveIntroduction } = useSaveMatchApplicantIntroduction(
    () => {
      history.push(RoutePaths.matchingGuide(positionSn));
    },
    (error) => {
      console.error('자기소개 저장 실패:', error);
    },
  );
  const { data: matchingInformationFieldExists, isError } = useCheckMatchApplicantMatchingInformationFieldExists(positionSn);
  const isFinalSubmit = matchingInformationFieldExists?.isFinalSubmit;
  useEffect(() => {
    if (isFinalSubmit) {
      history.push(RoutePaths.matchingGuide(positionSn));
    }
  }, [isFinalSubmit]);
  useEffect(() => {
    if (positionSn) {
      if (positionSn === -1) {
        history.push(RoutePaths.matchingGuide(positionSn));
      }
    } else {
      history.push(RoutePaths.matchingGuide(positionSn));
    }
  }, [positionSn]);

  useEffect(() => {
    if (introductionData) {
      if (introductionData.title) {
        setTitle(introductionData.title);
      }
      if (introductionData.content) {
        setIntroduceText(introductionData.content);
      }
    }
  }, [introductionData]);

  const handlePreviousStep = () => {
    history.push(RoutePaths.matchingGuide(positionSn));
  };
  const onSubmitIntroduce = () => {
    saveIntroduction({
      title,
      content: introduceText,
    });
  };
  const handleClear = () => {
    setTitle('');
  };

  return (
    <SelfIntroduceFrame>
      <Header>
        <IconLogo className='logo' />
        <button
          className='btn-back'
        >
          <IconArrowBack onClick={handlePreviousStep} />
        </button>
      </Header>

      {/* 자기소개 */}
      <div className='wrap'>
        <V2Text
          fontStyle={V2FontStyleType.body_3_sb}
          color={V2TextColorsType.brand}
        >
          STEP 1
        </V2Text>
        <SpacingBlock size={SpaceValue.XS} vertical />
        <V2Text
          fontStyle={V2FontStyleType.title_1_b}
          color={V2TextColorsType.default}
        >
          간단한 자기 소개를<br />작성해주세요.
        </V2Text>
        <SpacingBlock size={SpaceValue.L} vertical />
        <V2Text
          fontStyle={V2FontStyleType.body_1_m}
          color={V2TextColorsType.subtle}
        >
          자신을 가장 잘 나타낼 수 있는 문구를 작성해 주세요.
          <br />
          입력하신 문구는 프로필 최상단에 노출됩니다.
        </V2Text>

        {/* 예시 이미지 */}
        <SpacingBlock size={SpaceValue.XXL} vertical />
        <V2Text
          fontStyle={V2FontStyleType.body_3_m}
          color={V2TextColorsType.default}
        >
          예시 이미지
        </V2Text>
        <SpacingBlock size={SpaceValue.M} vertical />
        <img src={ImgProfile} alt='예시 이미지' />
        <SpacingBlock size={SpaceValue.XXL} vertical />

        {/* 한문장 소개 */}
        <V2Text
          fontStyle={V2FontStyleType.body_3_m}
          color={V2TextColorsType.default}
        >
          한문장 소개
        </V2Text>
        <SpacingBlock size={SpaceValue.M} vertical />
        <div className='input-container'>
          <input
            type='text'
            className='custom-input'
            placeholder='안녕하세요! 긍정의 스위치, 김조이입니다.'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            maxLength={titleMaxLength}
          />
          {title && <button className='clear-button' onClick={handleClear}>✕</button>}
          <div className='length-title'>{title.length}/{titleMaxLength}</div>
        </div>

        {/* 자기소개 */}
        <SpacingBlock size={SpaceValue.XXL} vertical />
        <V2Text
          fontStyle={V2FontStyleType.body_3_m}
          color={V2TextColorsType.default}
        >
          자기소개
        </V2Text>
        <SpacingBlock size={SpaceValue.M} vertical />
        <div className='text-area-container'>
          <textarea
            className='custom-textarea'
            placeholder='안녕하세요! 저는 밝은 에너지를 주변에 전하는 긍정의 스위치, 김조이입니다. 사람들과의 소통과 협업에서 가치를 찾는 것을 중요하게 생각합니다. 어떤 상황에서도 열정을 잃지 않고 목표를 이루기 위해 끊임없이 도전하는 것이 제 강점입니다.'
            value={introduceText}
            onChange={(e) => setIntroduceText(e.target.value)}
            maxLength={maxLength}
          />
          <div className='length-text'>{introduceText.length}/{maxLength}</div>
        </div>
      </div>

      <ButtonFrame>
        <V2Button
          type={V2ButtonOption.type.Fill}
          size={V2ButtonOption.size.L}
          fill={V2ButtonOption.fillType.brand_strong_default}
          fontStyle={V2ButtonOption.fontStyle.body_1_sb}
          color={V2ButtonOption.color.inverse}
          styles={{ width: '100%' }}
          buttonProps={{ onClick: onSubmitIntroduce, disabled: !title || !introduceText }}

        >
          저장
        </V2Button>
      </ButtonFrame>
    </SelfIntroduceFrame>
  );
};

export default MatchingGuideIntoduce;
