import SpacingBlock from '__designkit__/components/SpacingBlock';
import V2Icon from '__pc__/components/common/v2Design/icon';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { V2TextColorsType } from '__pc__/constant/v2Design/Colors/V2TextColors';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2FontStyleType } from '__pc__/constant/v2Design/V2FontStyle';
import { V2IconOption } from '__pc__/constant/v2Design/V2IconType';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import URIs from 'consts/URIs';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import request from 'utils/request';
import CommonUtils from 'utils/CommonUtils';
import { useHistory, useLocation } from 'react-router';
import AuthorizeUtil from 'utils/AuthorizeUtil';
import Login from 'models/Login';
import Context from 'models/Context';
import RoutePaths from 'consts/RoutePaths';
import { inject, observer } from 'mobx-react';
import { injectStore } from 'models/store';
import { IGetExistFilesRs } from '../matchingGuide/MatchingGuideMainPage';
import { useCheckMatchApplicantFilesExist } from 'query/match/applicants/useMatchApplicantsQuery';
import ImgProfile from './imgDailyGuide.png';
const Frame = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  padding: ${SpaceValue.XXL}px ${SpaceValue.L}px;
  height: calc(100vh - 90px);
  
  /* 버튼 리스트 */
  .btn-list {
    display: flex;
    flex-direction: column;
    gap: ${SpaceValue.XS}px;
    width: 100%;

    .apply-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: ${SpaceValue.XL}px;
      border: 1px solid ${V2BorderColors.default};
      border-radius: ${SpaceValue.M}px;

      .badge {
        display: flex;
        align-items: center;
        gap: ${SpaceValue.XXXS}px;
      }
    }
  }
`;

export enum VideoAndImagesType{
  MATCHING_SELF_INTRODUCTION_VIDEO = 'MATCHING_SELF_INTRODUCTION_VIDEO',
  MATCHING_MOTIVATION_VIDEO = 'MATCHING_MOTIVATION_VIDEO',
  MATCHING_PROFILE_PHOTO = 'MATCHING_PROFILE_PHOTO',
  MATCHING_SELF_EXPRESSION_PHOTO = 'MATCHING_SELF_EXPRESSION_PHOTO',
}

interface IUploadIntro{
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setVideoType: React.Dispatch<React.SetStateAction<VideoAndImagesType | null>>;
  login?:Login
  context?:Context
}
const UploadIntro:React.FC<IUploadIntro> = ({ setCurrentStep, setVideoType, login = new Login(), context = new Context() }) => {
  const [isSelfIntroduceVideo, setIsSelfIntroduceVideo] = useState<boolean>(false);
  const [isMotivationVideo, setIsMotivationVideo] = useState<boolean>(false);
  const [isProfilePhoto, setIsProfilePhoto] = useState<boolean>(false);
  const [isSelfExpressionPhoto, setIsSelfExpressionPhoto] = useState<boolean>(false);
  const { pathname, search } = useLocation();
  const { accessToken, refreshToken } = CommonUtils.parseQuery(search);

  const history = useHistory();
  const { data: filesExistData } = useCheckMatchApplicantFilesExist();

  useEffect(() => {
    if (!login?.userInfo) {
      if (accessToken && refreshToken) {
        AuthorizeUtil.setAccessToken(accessToken);
        AuthorizeUtil.setRefreshToken(refreshToken);
      } else {
        context.loginRedirectPath = pathname;
        history.push(RoutePaths.login);
      }
    }
  }, [accessToken, refreshToken]);
  useEffect(() => {
    if (filesExistData) {
      setIsSelfIntroduceVideo(filesExistData.hasMatchingSelfIntroductionVideo);
      setIsMotivationVideo(filesExistData.hasMatchingMotivationVideo);
      setIsProfilePhoto(filesExistData.hasMatchingProfilePhoto);
      setIsSelfExpressionPhoto(filesExistData.hasMatchingSelfExpressionPhoto);
    }
  }, [filesExistData]);


  const CheckListItems = [
    // { label: '자기소개 영상', status: isSelfIntroduceVideo ? '완료' : '미완료', type: VideoAndImagesType.MATCHING_SELF_INTRODUCTION_VIDEO },
    // { label: '지원동기 영상', status: isMotivationVideo ? '완료' : '미완료', type: VideoAndImagesType.MATCHING_MOTIVATION_VIDEO },
    { label: '프로필 사진', status: isProfilePhoto ? '완료' : '미완료', type: VideoAndImagesType.MATCHING_PROFILE_PHOTO },
    { label: '일상 사진', status: isSelfExpressionPhoto ? '완료' : '미완료', type: VideoAndImagesType.MATCHING_SELF_EXPRESSION_PHOTO },
  ];

  const handleNextStep = (type:VideoAndImagesType) => {
    if (type === VideoAndImagesType.MATCHING_SELF_INTRODUCTION_VIDEO) {
      setVideoType(VideoAndImagesType.MATCHING_SELF_INTRODUCTION_VIDEO);
      setCurrentStep(1);
    } else if (type === VideoAndImagesType.MATCHING_MOTIVATION_VIDEO) {
      setVideoType(VideoAndImagesType.MATCHING_MOTIVATION_VIDEO);
      setCurrentStep(1);
    } else if (type === VideoAndImagesType.MATCHING_PROFILE_PHOTO) {
      setCurrentStep(9);
    } else {
      setCurrentStep(11);
    }
  };
  return (
    <Frame>
      <V2Text fontStyle={V2FontStyleType.body_3_sb} color={V2TextColorsType.brand}>
        STEP 2
      </V2Text>
      <SpacingBlock size={SpaceValue.XXXS} vertical />
      <V2Text fontStyle={V2FontStyleType.title_1_b} color={V2TextColorsType.default}>
        사진을 제출해주세요.
      </V2Text>
      <SpacingBlock size={SpaceValue.M} vertical />
      <V2Text fontStyle={V2FontStyleType.body_1_m} color={V2TextColorsType.subtle}>
        JOBDA에서 제공하는 가이드에 맞추어
        <br />
        프로필 사진과 일상 사진을 제공해주세요.
      </V2Text>
              {/* 예시 이미지 */}
              <SpacingBlock size={SpaceValue.XXL} vertical />
        <V2Text
          fontStyle={V2FontStyleType.body_3_m}
          color={V2TextColorsType.default}
        >
          예시 이미지
        </V2Text>
        <SpacingBlock size={SpaceValue.M} vertical />
        <img src={ImgProfile} alt='예시 이미지' />
        <SpacingBlock size={SpaceValue.XXL} vertical />


      <div className='btn-list'>
        {CheckListItems.map(({ label, status, type }) => {
          return status === '완료' ? (
            <div role='button' onClick={() => handleNextStep(type)} className='apply-title' key={label}>
              <V2Text element={V2TextOption.element.span} fontStyle={V2TextOption.fontStyle.body_1_b} color={V2TextOption.color.default}>
                {label}
              </V2Text>
              <div className='badge'>
                <V2Icon
                  name={V2IconOption.name.checkCircleFilled}
                  size={V2IconOption.size.S}
                  fill={V2IconOption.fill.primary}
                />
                <V2Text element={V2TextOption.element.span} fontStyle={V2TextOption.fontStyle.body_2_sb} color={V2TextOption.color.success}>
                  {status}
                </V2Text>
              </div>
            </div>
          ) : (
            <div role='button' onClick={() => handleNextStep(type)} className='apply-title' key={label}>
              <V2Text element={V2TextOption.element.span} fontStyle={V2TextOption.fontStyle.body_1_b} color={V2TextOption.color.default}>
                {label}
              </V2Text>
              <div className='badge'>
                <V2Icon
                  name={V2IconOption.name.errorFilled}
                  size={V2IconOption.size.S}
                  fill={V2IconOption.fill.accent_red}
                />
                <V2Text element={V2TextOption.element.span} fontStyle={V2TextOption.fontStyle.body_2_sb} color={V2TextOption.color.danger}>
                  {status}
                </V2Text>
              </div>
            </div>
          );
        })}
      </div>
    </Frame>
  );
};

export default inject(injectStore.context, injectStore.login)(observer(UploadIntro));
