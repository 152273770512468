import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import BuilderAtsErrorModal from 'components/_v2/builder/BuilderAtsErrorModal';
import BuilderLoginInfoModal from 'components/_v2/builder/BuilderLoginInfoModal';
import LoginForm from 'components/_v2/login/LoginForm';
import SignUpFrame from 'components/_v2/login/SignUpFrame';
import SocialLoginForm from 'components/_v2/login/SocialLoginForm';
import ThirdPartyPrivacyModal from 'components/_v2/terms/ThirdPartyPrivacyModal';
import RoutePaths from 'consts/RoutePaths';
import ILoginPageProps from 'interfaces/props/ILoginPageProps';
import { inject, observer } from 'mobx-react';
import JoinModel from 'models/_v2/JoinModel';
import { lineClampBox } from 'consts/style/mixins';
import ResumeModel from 'models/_v2/ResumeModel';
import { injectStore } from 'models/store';
import { useInsightAceessToken } from 'query/resume/useResumeQuery';
import { useAgreeTermsThirdPartyPrivacyProvision, useTermsThirdPartyPrivacyProvision, useTermsThirdPartyPrivacyProvisionAgreement } from 'query/terms/useTermsQuery';
import { FC, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import { windowOpenUtils } from 'utils/_v2/windowOpenUtils';
import AuthorizeUtil from 'utils/AuthorizeUtil';
import CommonUtils, { removeSession } from 'utils/CommonUtils';
import TodayRenderCheckUtil from 'utils/TodayRenderCheckUtil';
import { JDMainButton } from 'consts/_v2/_common/style/mixins';
import { DateUtil } from 'components/_v2/_common/input/datePicker/DateUtil';
import { DateFormatYYYYMMDD } from 'utils/DateUtils';
import { UserAuthorityType } from 'consts/_v2/UserAuthorityType';
import Login from 'models/Login';

const Frame = styled.div`
  height: 100%;
  background: ${colors.WHITE_100};
  padding: 0 20px 16px 20px;
  max-width: 460px;
  min-width: 360px;
  margin: auto;

  .agree {
    margin-top: 40px;
    /* 영역 스타일링 추가시 삭제 */
    background-color: pink;
  }
`;

const TitleFrame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${colors.JOBDA_BLACK};

  .sub-title {
    display: flex;
    font: ${Fonts.B1_Medium};
    margin-top: 8px;
  }
  .main-title {
    margin-top: 32px;
    text-align: center;
    font: ${Fonts.H3_Bold};
  }
`;

export const PROVIDE_ITEMS = '성명, 이메일, 휴대전화번호';
const ApplyFrame = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
  padding-top: 16px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: ${colors.CG_40};
  }

  .apply-info {
    display: flex;
    align-items: center;
    gap: 16px;

    .apply-desc {
      font: ${Fonts.B2_Medium_P};
      color: ${colors.CG_90};
      ${lineClampBox(1)};

      &.apply-date {
        display: flex;
        align-items: center;
        gap: 22px;

        .d-day {
          position: relative;
          font: ${Fonts.B2_Medium_P};
          color: ${colors.ERROR};

          &::after {
            content: '';
            position: absolute;
            top: 5px;
            right: -11px;
            display: inline-block;
            width: 1px;
            height: 12px;
            background-color: ${colors.CG_50};
          }
        }

        .end-date {
          font: ${Fonts.B2_Medium_P};
          color: ${colors.CG_90};
        }
      }
    }
  }
`;

const YesLoginFrame = styled.div`
  .user-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 24px;

    .login-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;

      .title {
        font: ${Fonts.B2_Medium_P};
        color: ${colors.CG_90};
      }

      .info {
        display: flex;
        align-items: center;
        padding: 0 14px;
        width: calc(100% - 56px);
        height: 56px;
        border-radius: 4px;
        background: ${colors.CG_30};
        font: ${Fonts.B2_Medium_P};
        color: ${colors.CG_90};
      }
    }
  }

  button {
    margin-top: 24px;
    font: ${Fonts.B1_Bold};
    height: 52px;
    border-radius: 4px;
    ${JDMainButton(false)};
  }
`;

const AgreeFrame = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    .check-frame{
        display: flex;
        .check-icon{

        }
        .check-title{
            font:${Fonts.B2_Medium_P};
            color:${colors.CG_90};
            margin-left: 8px;
        }
    }
    .more-icon{

    }
`;
export enum JobflexQuery {
    resume = 'resume',
    mypage = 'mypage',
  }

export enum LanguageType {
    KOR = 'KOR',
    ENG = 'ENG',
  }
interface IJobflexLogin extends ILoginPageProps {
  resumeModel?: ResumeModel;
}
const JobflexLogin: FC<IJobflexLogin> = (props) => {
  const { login = new Login(), joinModel = new JoinModel(), resumeModel = new ResumeModel() } = props;
  const [isInfoModalOpen, setIsInfoModalOpen] = useState<boolean>(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
  const [openPrivacyNotice, setOpenPrivacyNotice] = useState(false);
  const { pathname, search } = useLocation();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [loginAction, setLoginAction] = useState<boolean>(false);
  const { messageUrl, jobnoticeSn, resumeDomain, companyName, languageType, jobNoticeName, applyStartDate, applyEndDate, type, redirectUrl } = CommonUtils.parseQuery(search);
  const history = useHistory();
  const isJobFlexResume = type === JobflexQuery.resume;
  const isJobFlexMyPage = type === JobflexQuery.mypage;
  const keyName = 'jf3';

  useEffect(() => {
    if (jobnoticeSn) {
      TodayRenderCheckUtil.setPositionSn(keyName, String(jobnoticeSn));
    }
  }, [jobnoticeSn]);
  const getDate = (date: string) => new Date(+date.slice(0, 4), +date.slice(4, 6) - 1, +date.slice(6, 8));
  const formatDate = (dateString:Date) => {
    // Create a Date object from the input string
    const date = new Date(dateString);

    // Extract the year, month, and day
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');

    // Format as YYYY-MM-DD
    return `${year}-${month}-${day}`;
  };

  const convertStartDate = getDate(applyStartDate as string);
  const convertEndDate = getDate(applyEndDate as string);

  const getLeftDays = () => DateUtil.getLeftDays(DateUtil.getNow(), convertEndDate);
  const getDateRange = getLeftDays() > 0 ? `D-${getLeftDays()}` : 'D-day';

  const getApplyDate = () => `${formatDate(convertStartDate)} ~ ${formatDate(convertEndDate)}`;
  // 제3자 동의 자세히부분
  const { data: thirdPartyPrivacyProvisionData } = useTermsThirdPartyPrivacyProvision({
    termsType: 'INSIGHT_PRIVACY_PROVISION',
    companyName: String(companyName ?? ''),
    provideItems: PROVIDE_ITEMS,
  });

  // 제3자 동의했는지 안했는지 여부
  const { mutateAsync: fetchThirdPartyPrivacyProvisionAgreement } = useTermsThirdPartyPrivacyProvisionAgreement();
  const { mutateAsync: fetchAgreeThirdPartyPrivacyProvisionAgreement } = useAgreeTermsThirdPartyPrivacyProvision();
  const { mutateAsync: fetchInsightAccessToken } = useInsightAceessToken();
  // ats 수동 인재 연동
  const checkMatchLogin = () => {

  };
  const openJobflexResume = async () => {
    if (!thirdPartyPrivacyProvisionData) return;
    // 제3자제공 동의 여부 조회
    const { privacyProvisionYn } = await fetchThirdPartyPrivacyProvisionAgreement({
      termsType: 'INSIGHT_PRIVACY_PROVISION',
      companyName: `${companyName}`,
    });
    // 동의 안했다면 동의 api호출
    if (!privacyProvisionYn)
      await fetchAgreeThirdPartyPrivacyProvisionAgreement({
        termsSn: thirdPartyPrivacyProvisionData.sn,
        companyName: `${companyName}`,
        provideItems: PROVIDE_ITEMS,
      });
    const { accessToken } = await fetchInsightAccessToken();
    if (accessToken === null) throw new Error('accessToken is null');
    if (login?.userInfo?.authorities.includes(UserAuthorityType.MOBILE_UPDATE_REQUIRED_BY_NULL)) {
      login.jobflexType = type;
      login.jobflexResumeDomain = resumeDomain;
      login.jobflexJobnoticeSn = jobnoticeSn;
      login.jobflexAccessToken = accessToken;
      login.jobflexRedirectUrl = redirectUrl;
      history.push(RoutePaths.update_mobile);
    } else {
      try {
        if (isJobFlexResume) {
          const url = `${resumeDomain}?systemKindCode=MRS2&jobnoticeSn=${jobnoticeSn}&accessToken=${accessToken}`;
          windowOpenUtils(url, '_self');
        } else {
          const url = `${redirectUrl}&accessToken=${accessToken}`;
          windowOpenUtils(url, '_self');
        }
      } catch (error) {
        setIsErrorModalOpen(true);
      }
    }
  };
  useEffect(() => {
    if (login?.userInfo) {
      openJobflexResume();
    }
  }, [thirdPartyPrivacyProvisionData]);

  useEffect(() => {
    if (login?.userInfo && (loginAction || isJobFlexMyPage) && isChecked) {
      openJobflexResume();
    }
  }, [login?.userInfo, loginAction, isChecked, isJobFlexMyPage, isChecked]);

  useEffect(() => {
    joinModel.socialJoinInfo = null;
    removeSession('socialInfo');
  }, []);
  const defaultLanguage: LanguageType = LanguageType.KOR;
  const languageKey = (languageType as LanguageType) || defaultLanguage;

  const currentLanguage = useMemo(() => {
    return translations[languageKey] || translations[defaultLanguage];
  }, [languageType]);
  return (
    <>
      <Frame>
        <TitleFrame>
          <div className='main-title'>
            {currentLanguage.title}
          </div>
        </TitleFrame>

        {/* 지원 정보 */}
        <ApplyFrame>
          <div className='apply-info'>
            <Icon name='business' size={24} />
            <span className='apply-desc'>{companyName}</span>
          </div>
          <div className='apply-info'>
            <Icon name='work' size={24} />
            <span className='apply-desc'>{jobNoticeName.replaceAll('+', ' ')}</span>
          </div>
          <div className='apply-info'>
            <Icon name='calender' size={24} />
            <div className='apply-desc apply-date'>
              <span className='d-day'>{getDateRange}</span>
              <span className='end-date'>{getApplyDate()}</span>
            </div>
          </div>
        </ApplyFrame>

        {/* 로그인 폼 */}
        { isJobFlexResume
          ? login?.userInfo
            ? (
              <YesLoginFrame>
                <div className='user-info'>
                  <div className='login-info'>
                    <span className='title'>{currentLanguage.id}</span>
                    <div className='info'>{login.userInfo.id}</div>
                  </div>
                  <div className='login-info'>
                    <span className='title'>{currentLanguage.name}</span>
                    <div className='info'>{login.userInfo.name}</div>
                  </div>
                </div>
                <div className='login-btn'>
                  <AgreeFrame>
                    <div role='button' className='check-frame' onClick={() => setIsChecked(!isChecked)}>
                      <Icon name='check-circle-filled' size={24} color={isChecked ? colors.G_150 : colors.CG_50} />
                      <div className='check-title'>{currentLanguage.agreeText}</div>
                    </div>
                    <div role='button' onClick={() => setOpenPrivacyNotice(true)}>
                      <Icon name='large-arrow-right' size={24} color={colors.CG_50} />
                    </div>
                  </AgreeFrame>
                  <button
                    type='button'
                    onClick={() => setLoginAction(true)}
                  >
                    {currentLanguage.loginButton}
                  </button>
                </div>
              </YesLoginFrame>
            )
            : <LoginForm languageType={languageType as LanguageType || LanguageType.KOR} insight isChecked={isChecked} setLoginAction={setLoginAction} setIsChecked={setIsChecked} setOpenPrivacyNotice={setOpenPrivacyNotice} />
          : <LoginForm languageType={languageType as LanguageType || LanguageType.KOR} mypage isChecked={isChecked} setLoginAction={setLoginAction} setIsChecked={setIsChecked} setOpenPrivacyNotice={setOpenPrivacyNotice} />}

        {/* 아이디 이름 FORM */}

        <SocialLoginForm languageType={languageType as LanguageType || LanguageType.KOR} checkMatchLogin={checkMatchLogin} />
        <SignUpFrame languageType={languageType as LanguageType || LanguageType.KOR} checkMatchLogin={checkMatchLogin} />
      </Frame>
      <ThirdPartyPrivacyModal
        content={thirdPartyPrivacyProvisionData?.contents}
        isVisible={openPrivacyNotice}
        onCheck={() => {
          setIsChecked(true);
          setOpenPrivacyNotice(false);
        }}
        onClose={() => setOpenPrivacyNotice(false)}
      />
      <BuilderLoginInfoModal isOpen={isInfoModalOpen} onClickClose={() => setIsInfoModalOpen(false)} />
      <BuilderAtsErrorModal isOpen={isErrorModalOpen} onClickClose={() => setIsErrorModalOpen(false)} />
    </>
  );
};

export default inject(injectStore.resumeModel, injectStore.login, injectStore.joinModel)(observer(JobflexLogin));
const translations: Record<LanguageType, {
  title: string;
  findIdPW:string;
  id:string;
  name:string;
  agreeText: string;
  loginButton: string;
}> = {
  [LanguageType.ENG]: {
    title: 'Sign in to JOBDA and Apply to 750+ Companies Effortlessly',
    name: 'Name',
    id: 'ID',
    findIdPW: 'Find ID/PW',
    agreeText: 'I agree to the provision of personal information to third parties. (Required)',
    loginButton: 'Sign in',
  },
  [LanguageType.KOR]: {
    title: `잡다 로그인하고 750개 이상의\n기업에 간편히 지원해 보세요.`,
    name: '이름',
    id: '아이디',
    findIdPW: '아이디ㆍ비밀번호 찾기',
    agreeText: '개인정보 제 3자 제공에 동의합니다. (필수)',
    loginButton: '로그인',
  },
};
