/* eslint-disable @typescript-eslint/no-shadow */
import V2Button from '__pc__/components/common/v2Design/button';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import { V2TextOption } from '__pc__/constant/v2Design/V2TextType';
import JDFullModal from 'components/_v2/_common/modals/JDFullModal';
import Loading from 'components/Loading';
import React, { useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';
import styled from 'styled-components';

const Header = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: calc(64px + env(safe-area-inset-top));
  padding: calc(12px + env(safe-area-inset-top)) 12px;
  background-color: white;
  z-index: 9999;

`;
const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
`;

interface ImageEasyCropModalProps {
  imgSrc: string;
  isOpen: boolean;
  onClickClose: () => void;
  onChange?: any;
}

const ImageEasyCropModal: React.FC<ImageEasyCropModalProps> = ({ imgSrc, isOpen, onClickClose, onChange }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCropConfirm = async () => {
    if (croppedAreaPixels) {
      setIsLoading(true); // 크롭 시작시 로딩 시작
      const croppedCanvas = await getCroppedCanvas(imgSrc, croppedAreaPixels);
      if (onChange) {
        await onChange(croppedCanvas);
        setIsLoading(false); // 크롭 완료 후 로딩 종료
      }
    }
    onClickClose();
  };

  if (!isOpen) return null;
  const getCroppedCanvas = async (imageSrc: string, pixelCrop: any): Promise<HTMLCanvasElement | null> => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) return null;

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.drawImage(
      image,
      pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height,
      0, 0, pixelCrop.width, pixelCrop.height,
    );

    return canvas;
  };

  const createImage = (url: string): Promise<HTMLImageElement> => new Promise((resolve, reject) => {
    const image = new Image();
    image.src = url;
    image.crossOrigin = 'anonymous';
    image.onload = () => resolve(image);
    image.onerror = (error) => reject(error);
  });
  if (isLoading) return <Loading />;

  return (
    <JDFullModal>
      <Header>
        <V2Button
          type={V2ButtonOption.type.Text}
          size={V2ButtonOption.size.XS}
          color={V2ButtonOption.color.subtle}
          fontStyle={V2ButtonOption.fontStyle.body_3_sb}
          buttonProps={{ onClick: onClickClose }}
        >취소
        </V2Button>
        <V2Text
          fontStyle={V2TextOption.fontStyle.title_3_b}
          color={V2TextOption.color.default}
        >
          사진 수정
        </V2Text>
        <V2Button
          type={V2ButtonOption.type.Text}
          size={V2ButtonOption.size.XS}
          color={V2ButtonOption.color.subtle}
          fontStyle={V2ButtonOption.fontStyle.body_3_sb}
          buttonProps={{ onClick: handleCropConfirm }}
        >완료
        </V2Button>
      </Header>
      <Container>
        <Cropper
          image={imgSrc}
          crop={crop}
          zoom={zoom}
          aspect={1}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
        />
      </Container>
    </JDFullModal>
  );
};

export default ImageEasyCropModal;
