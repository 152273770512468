/* eslint-disable prefer-destructuring */
import SpacingBlock from '__designkit__/components/SpacingBlock';
import V2Button from '__pc__/components/common/v2Design/button';
import V2Icon from '__pc__/components/common/v2Design/icon';
import V2Text from '__pc__/components/common/v2Design/text';
import { V2BorderColors } from '__pc__/constant/v2Design/Colors/V2BorderColors';
import { V2TextColors, V2TextColorsType } from '__pc__/constant/v2Design/Colors/V2TextColors';
import { RadiusValue } from '__pc__/constant/v2Design/RadiusType';
import { SpaceValue } from '__pc__/constant/v2Design/SpaceType';
import { V2ButtonOption } from '__pc__/constant/v2Design/V2ButtonType';
import { V2FontStyleDirect, V2FontStyleType } from '__pc__/constant/v2Design/V2FontStyle';
import { useMatchApplicantFiles, useUploadMatchApplicantPhotosSelfExpression } from 'query/match/applicants/useMatchApplicantsQuery';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import FileUtil from 'utils/FileUtil';
import IconChange from './icon_change.svg';
import { VideoAndImagesType } from './UploadIntro';

const Frame = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  padding: ${SpaceValue.XXL}px ${SpaceValue.L}px 128px ${SpaceValue.L}px;
  .file-wrap{
    display: flex;
    flex-direction: column;
    gap: ${SpaceValue.L}px;
    .file-upload {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${SpaceValue.XS}px;
    margin: ${SpaceValue.XL}px 0 ${SpaceValue.L}px 0;
    height: 343px;
    border: 1px solid ${V2BorderColors.default};
    border-radius: ${RadiusValue.L}px;
    background-color: white;

    &.has-upload {
      background-color: black;
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
      /* 한문장 소개 */
    .input-container {
      position: relative;
      width: 100%;
      height: 104px;
      border: 1px solid ${V2BorderColors.default};
      border-radius: ${RadiusValue.M}px;
    }

    .custom-input {
      width: 100%;
      height: 100%;
      padding: ${SpaceValue.M}px;
      padding-right: 40px; /* 버튼 공간 확보 */
      border: 1px solid ${V2BorderColors.default};
      border-radius: ${RadiusValue.M}px;
      font: ${V2FontStyleDirect.body_1_m};
      color: ${V2TextColors.default};
      outline: none;
      border: none;

      &::placeholder {
        color: ${V2TextColors.subtlest};
      }
    }
    .length-title {
      position: absolute;
      right: 0;
      bottom: -28px;
      font: ${V2FontStyleDirect.body_3_m};
      color: ${V2TextColors.subtlest};
    }

    .length-text {
      position: absolute;
      right: 12px;
      bottom: 7px;
      font: ${V2FontStyleDirect.body_3_m};
      color: ${V2TextColors.subtlest};
    }
  }
  

  .file-change {
    position: absolute;
    right: 12px;
    bottom: 12px;
    
    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
`;

interface IUploadDailyPhotos{
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}
const UploadDailyPhotos: React.FC<IUploadDailyPhotos> = ({ setCurrentStep }) => {
  const [images, setImages] = useState<(File | null)[]>([null, null, null]);
  const [previewUrls, setPreviewUrls] = useState<(string | null)[]>([null, null, null]);
  const [descriptions, setDescriptions] = useState<string[]>(['', '', '']);
  const [isLoading, setIsLoading] = useState(false);
  const examplePalceHolder = [
    '낯선 곳에 가는 것을 좋아해요.',
    '사람들과 소통하는 것을 즐기고 언제나 긍정적인 기운을 갖는 사람입니다.',
    '무언갈 만드는 취미에 빠져있어요',
  ];
  const { data: filesData } = useMatchApplicantFiles(VideoAndImagesType.MATCHING_SELF_EXPRESSION_PHOTO);
  const { mutate: uploadPhotos } = useUploadMatchApplicantPhotosSelfExpression(
    () => {
      setCurrentStep(0);
      setIsLoading(false);
    },
    (error) => {
      console.error('Failed to upload photos:', error);
      setIsLoading(false);
    },
  );
  useEffect(() => {
    const init = async () => {
      try {
        if (filesData?.files[0].fileUrl) {
          const filePromises = filesData.files.slice(0, 3).map(async (file) => {
            const fileExt = file.fileName.split('.').pop()?.toLowerCase();
            const mimeType = fileExt === 'png' ? 'image/png'
              : fileExt === 'jpg' || fileExt === 'jpeg' ? 'image/jpeg'
                : 'image/jpeg'; // 기본값

            const existingFile = await FileUtil.urlToFile(file.fileUrl, file.fileName, mimeType);
            return { file: existingFile, url: file.fileUrl };
          });

          const filesWithUrls = await Promise.all(filePromises);
          const newImages = filesWithUrls.map((item) => item.file);
          const newPreviewUrls = filesWithUrls.map((item) => item.url);

          setImages([...newImages, ...Array(3 - newImages.length).fill(null)]);
          setPreviewUrls([...newPreviewUrls, ...Array(3 - newPreviewUrls.length).fill(null)]);
          const newDescriptions = [...descriptions];
          filesData.files.forEach((file, index) => {
            newDescriptions[index] = file.fileDescription || '';
          });
          setDescriptions(newDescriptions);
        }
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, [filesData]);

  const handleImageUpload = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const newImages = [...images];
      newImages[index] = file;
      setImages(newImages);

      const newUrls = [...previewUrls];
      newUrls[index] = URL.createObjectURL(file);
      setPreviewUrls(newUrls);
    }
  };

  const handleDescriptionChange = (index: number) => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newDescriptions = [...descriptions];
    newDescriptions[index] = e.target.value;
    setDescriptions(newDescriptions);
  };

  const handleUpload = async () => {
    if (!isAllInputsCompleted()) {
      return;
    }
    setIsLoading(true);

    const formData = new FormData();
    images.forEach((image) => {
      if (image) {
        formData.append('multipartFiles', image);
      }
    });

    try {
      uploadPhotos({
        formData,
        descriptions: descriptions.filter((_, index) => images[index] !== null),
      });
    } catch (error) {
      console.error('Failed to upload photos:', error);
      setIsLoading(false);
    }
  };
  const isAllInputsCompleted = () => {
    return !images.includes(null) && descriptions.every((desc) => desc.trim() !== '');
  };

  return (
    <Frame>
      <V2Text
        fontStyle={V2FontStyleType.body_3_sb}
        color={V2TextColorsType.brand}
      >
        일상 사진 가이드
      </V2Text>
      <SpacingBlock size={SpaceValue.XXXS} vertical />
      <V2Text
        fontStyle={V2FontStyleType.title_1_b}
        color={V2TextColorsType.default}
      >
        나를 표현할 수 있는 사진
      </V2Text>
      <SpacingBlock size={SpaceValue.M} vertical />
      <V2Text
        fontStyle={V2FontStyleType.body_1_m}
        color={V2TextColorsType.subtle}
      >
        나를 잘 나타낼 수 있는 사진 3장을 첨부하고 각 사진에 어울리는 나를 표현할 수 있는 문구(40자 이내)도 함께 작성해 주세요.
      </V2Text>

      {/* 업로드 Input */}
      <div className='file-wrap'>
        {[0, 1, 2].map((index) => (
          <React.Fragment key={index}>
            <div className={`file-upload ${previewUrls[index] ? 'has-upload' : ''}`}>
              {previewUrls[index] ? (
                <img src={previewUrls[index] ?? undefined} alt='Uploaded Preview' />
              ) : (
                <>
                  <V2Icon name='add' />
                  <V2Text fontStyle={V2FontStyleType.body_1_m} color={V2TextColorsType.default}>
                    사진 추가하기
                  </V2Text>
                </>
              )}
              <input type='file' accept='image/png, image/jpeg' onChange={handleImageUpload(index)} />
              {previewUrls[index] && (
              <div className='file-change'>
                <img src={IconChange} alt='사진 수정' />
                <input type='file' accept='image/png, image/jpeg' onChange={handleImageUpload(index)} />
              </div>
              )}
            </div>
            <div className='input-container'>
              <textarea
                className='custom-input'
                value={descriptions[index]}
                onChange={handleDescriptionChange(index)}
                placeholder={examplePalceHolder[index]}
                maxLength={40}
              />
              <div className='length-title'>
                {descriptions[index].length}/40
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
      <div className='action-buttons'>
        <V2Button
          type={V2ButtonOption.type.Fill}
          size={V2ButtonOption.size.L}
          buttonProps={{ onClick: () => handleUpload(), disabled: !isAllInputsCompleted() }}
          fill={V2ButtonOption.fillType.brand_strong_default}
          fontStyle={V2ButtonOption.fontStyle.body_1_sb}
          color={V2ButtonOption.color.inverse}
          styles={{ width: '100%' }}
          isLoading={isLoading}
        >
          제출하기
        </V2Button>
      </div>
    </Frame>
  );
};

export default UploadDailyPhotos;
