import { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { RemoteMatchApplicantsRepo } from 'repository/match/applicants/MatchApplicantsRepo';
import { VideoAndImagesType } from 'components/_v2/videoRecord/UploadIntro';
import { IPostIntroductionRq, IUploadPhotosParams } from 'interfaces/_v2/MatchApplicants/IMatchApplicants';

const matchApplicantsRepo = new RemoteMatchApplicantsRepo();

const fetchMatchApplicantFiles = async (type: VideoAndImagesType) => {
  const data = await matchApplicantsRepo.loadMatchApplicantFiles(type);
  return data;
};

export function useMatchApplicantFiles(type: VideoAndImagesType) {
  return useQuery(
    ['match', 'applicant', 'files', type],
    () => fetchMatchApplicantFiles(type),
    {
      onError: (error) => {
        console.error('Failed to fetch match applicant files:', error);
      },
    },
  );
}

const fetchMatchApplicantIntroduction = async () => {
  const data = await matchApplicantsRepo.loadMatchApplicantIntroduction();
  return data;
};

export function useMatchApplicantIntroduction() {
  return useQuery(
    ['match', 'applicant', 'introduction'],
    fetchMatchApplicantIntroduction,
    {
      onError: (error) => {
        console.error('Failed to fetch match applicant introduction:', error);
      },
    },
  );
}

const saveMatchApplicantIntroduction = async (rq: IPostIntroductionRq) => {
  const data = await matchApplicantsRepo.saveMatchApplicantIntroduction(rq);
  return data;
};

export function useSaveMatchApplicantIntroduction(
  onSuccess?: () => void,
  onError?: (e: AxiosError) => void,
) {
  return useMutation(saveMatchApplicantIntroduction, {
    onSuccess,
    onError,
  });
}

const uploadMatchApplicantPhotosSelfExpression = async ({ formData, descriptions }: IUploadPhotosParams) => {
  const data = await matchApplicantsRepo.uploadMatchApplicantPhotosSelfExpression({ formData, descriptions });
  return data;
};

export function useUploadMatchApplicantPhotosSelfExpression(
  onSuccess?: () => void,
  onError?: (e: AxiosError) => void,
) {
  return useMutation(uploadMatchApplicantPhotosSelfExpression, {
    onSuccess,
    onError,
  });
}

const uploadMatchApplicantProfilePhoto = async (formData: FormData) => {
  const data = await matchApplicantsRepo.uploadMatchApplicantProfilePhoto(formData);
  return data;
};

export function useUploadMatchApplicantProfilePhoto(
  onSuccess?: () => void,
  onError?: (e: AxiosError) => void,
) {
  return useMutation(uploadMatchApplicantProfilePhoto, {
    onSuccess,
    onError,
  });
}

const uploadMatchApplicantVideo = async (formData: FormData) => {
  const data = await matchApplicantsRepo.uploadMatchApplicantVideo(formData);
  return data;
};

export function useUploadMatchApplicantVideo(
  onSuccess?: () => void,
  onError?: (e: AxiosError) => void,
) {
  return useMutation(uploadMatchApplicantVideo, {
    onSuccess,
    onError,
  });
}

const checkMatchApplicantFilesExist = async () => {
  const data = await matchApplicantsRepo.checkMatchApplicantFilesExist();
  return data;
};

export function useCheckMatchApplicantFilesExist() {
  return useQuery(
    ['match', 'applicant', 'files', 'exist'],
    () => checkMatchApplicantFilesExist(),
    {
      onError: (error) => {
        console.error('Failed to check match applicant files exist:', error);
      },
    },
  );
}

const checkMatchApplicantMatchingInformationFieldExists = async (positionSn: number) => {
  const data = await matchApplicantsRepo.checkMatchApplicantMatchingInformationFieldExists(positionSn);
  return data;
};

export function useCheckMatchApplicantMatchingInformationFieldExists(positionSn: number) {
  return useQuery(
    ['match', 'applicant', 'matching', 'information', 'field', 'exists', positionSn],
    () => checkMatchApplicantMatchingInformationFieldExists(positionSn),
    {
      retry: 0,
      onError: (error) => {
        console.error('Failed to check match applicant matching information field exists:', error);
      },
    },
  );
}

const saveMatchApplicantFinalApply = async (positionSn: number) => {
  const data = await matchApplicantsRepo.saveMatchApplicantFinalApply(positionSn);
  return data;
};

export function useSaveMatchApplicantFinalApply(
  onSuccess?: () => void,
  onError?: (e: AxiosError) => void,
) {
  return useMutation(saveMatchApplicantFinalApply, {
    onSuccess,
    onError,
  });
}
