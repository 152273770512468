/* eslint-disable class-methods-use-this */
import URIs from 'consts/URIs';
import { request } from 'utils/request';
import { VideoAndImagesType } from 'components/_v2/videoRecord/UploadIntro';
import { IGetIntroductionRs, IGetExistFilesRs, IAttachFile, IPostIntroductionRq, IGetMatchingInformationFieldExistsRs, IMatchApplicantPhotoUploadRq } from 'interfaces/_v2/MatchApplicants/IMatchApplicants';

import { IGetMatchingFilesRs } from 'components/_v2/videoRecord/UploadProfilePhotos';

interface IUploadPhotosParams {
  formData: FormData;
  descriptions: string[];
}
export default interface MatchApplicantsRepo {
  // 매칭 지원자 > 파일 조회
  loadMatchApplicantFiles(type: VideoAndImagesType): Promise<IGetMatchingFilesRs>;

  // 매칭 지원자 > 자기소개 조회
  loadMatchApplicantIntroduction(): Promise<IGetIntroductionRs>;

  // 매칭 지원자 > 자기소개 등록
  saveMatchApplicantIntroduction(rq: IPostIntroductionRq): Promise<void>;

  // 매칭 지원자 > 사진 업로드
  uploadMatchApplicantPhotosSelfExpression({ formData, descriptions }: IUploadPhotosParams): Promise<IAttachFile[]>;

  // 매칭 지원자 > 프로필 사진 업로드
  uploadMatchApplicantProfilePhoto(formData: FormData): Promise<IAttachFile[]>;

  // 매칭 지원자 > 영상 업로드
  uploadMatchApplicantVideo(formData: FormData): Promise<IAttachFile[]>;

  // 매칭 지원자 > 파일 존재 여부 확인
  checkMatchApplicantFilesExist(): Promise<IGetExistFilesRs>;

  // 매칭 지원자 > 매칭 정보 필드 존재 여부 확인
  checkMatchApplicantMatchingInformationFieldExists(positionSn: number): Promise<IGetMatchingInformationFieldExistsRs>;

  // 매칭 지원자 > 최종 제출
  saveMatchApplicantFinalApply(positionSn: number): Promise<void>;
}

export class RemoteMatchApplicantsRepo implements MatchApplicantsRepo {
  loadMatchApplicantFiles(type: VideoAndImagesType): Promise<IGetMatchingFilesRs> {
    return request({
      method: 'get',
      url: URIs.get_v1_match_applicants_files,
      params: { type },
    });
  }

  loadMatchApplicantIntroduction(): Promise<IGetIntroductionRs> {
    return request({
      method: 'get',
      url: URIs.get_v1_match_applicants_introduction,
    });
  }

  saveMatchApplicantIntroduction(rq: IPostIntroductionRq): Promise<void> {
    return request({
      method: 'post',
      url: URIs.post_v1_match_applicants_introduction,
      data: rq,
    });
  }

  uploadMatchApplicantPhotosSelfExpression({ formData, descriptions }: IUploadPhotosParams): Promise<IAttachFile[]> {
    const rq: IMatchApplicantPhotoUploadRq = { descriptions };

    formData.append('rq', JSON.stringify(rq));

    return request({
      method: 'post',
      url: URIs.post_v1_match_applicants_self_expression_photos,
      data: formData,
    });
  }

  uploadMatchApplicantProfilePhoto(formData: FormData): Promise<IAttachFile[]> {
    return request({
      method: 'post',
      url: URIs.post_v1_match_applicants_profile_photo,
      data: formData,
    });
  }

  uploadMatchApplicantVideo(formData: FormData): Promise<IAttachFile[]> {
    return request({
      method: 'post',
      url: URIs.post_v1_match_applicants_video,
      data: formData,
    });
  }

  checkMatchApplicantFilesExist(): Promise<IGetExistFilesRs> {
    return request({
      method: 'get',
      url: URIs.get_v1_match_applicants_files_exist,
    });
  }

  checkMatchApplicantMatchingInformationFieldExists(positionSn: number): Promise<IGetMatchingInformationFieldExistsRs> {
    return request({
      method: 'get',
      url: URIs.get_v1_match_applicants_matching_information_field_exists,
      params: { positionSn },
    });
  }

  saveMatchApplicantFinalApply(positionSn: number): Promise<void> {
    return request({
      method: 'post',
      url: URIs.post_v1_match_applicants_final_apply,
      data: { positionSn },
    });
  }
}
